import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import axios from "axios";

const Header = lazy(() => import("./components/Header"));
const Herosection = lazy(() => import("./components/Herosection"));
const GridSection = lazy(() => import("./components/GridSection"));
const GridCardSection = lazy(() => import("./components/GridCardSection"));
const RatingQuicklySection = lazy(() =>
  import("./components/RatingQuicklySection")
);
const VideoSection = lazy(() => import("./components/VideoSection"));
const CallUsNowSection = lazy(() => import("./components/CallUsNowSection"));
const ArialPhotoShoot = lazy(() => import("./components/ArialPhotoShoot"));
const WhyChooseSection = lazy(() => import("./components/WhyChooseSection"));
const SlickSliderSoldSection = lazy(() =>
  import("./components/SlickSliderSoldSection")
);
const AtTheRightPriceSection = lazy(() =>
  import("./components/AtTheRightPriceSection")
);
const BoatForMoreSlider = lazy(() => import("./components/BoatForMoreSlider"));
const ReviewSection = lazy(() => import("./components/ReviewSection"));
const FaqSection = lazy(() => import("./components/FaqSection"));
const SellBoatSection = lazy(() => import("./components/SellBoatSection"));
const Footer = lazy(() => import("./components/Footer"));
const NewData = lazy(() => import("./components/NewData"));
const PrivacyPolicies = lazy(() => import("./components/PrivacyPolicies"));
const TermsOfUse = lazy(() => import("./components/TermsOfUse"));

function App() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState();

  const getData = async () => {
    const backendUrl =
      process.env.REACT_APP_URL || "https://staging.muniveyachting.com";
    try {
      const response = await axios.get(
        `${backendUrl}/wordpress/wp-json/wp/v2/pages/397`
      );
      if (response.status === 200) {
        setData(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching the page data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header data={data?.acf} />
                <Herosection data={data?.acf} />
                <GridSection data={data?.acf} />
                <GridCardSection data={data?.acf} />
                <VideoSection data={data?.acf} />
                <section id="features">
                  <WhyChooseSection data={data?.acf} />
                </section>
                <NewData data={data?.acf} />
                <SlickSliderSoldSection data={data?.acf} />
                <AtTheRightPriceSection data={data?.acf} />
                <BoatForMoreSlider data={data?.acf} />
                <section id="reviews">
                  <ReviewSection data={data?.acf} />
                </section>
                <section id="faqs">
                  <FaqSection data={data?.acf} />
                </section>
                <SellBoatSection data={data?.acf} />
                <Footer data={data?.acf} />
              </>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <>
                <Header data={data?.acf} />
                <PrivacyPolicies data={data?.acf} />
                <Footer data={data?.acf} />
              </>
            }
          />
          <Route
            path="/terms-of-use"
            element={
              <>
                <Header data={data?.acf} />
                <TermsOfUse data={data?.acf} />
                <Footer data={data?.acf} />
              </>
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
